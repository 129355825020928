@import 'base.sass';
html {
    body {
        .front-footer {
            display: block;
            @include flex(center,wrap,row,center,center);
            width: 100%;
            background: linear-gradient(180deg, rgba(204, 208, 255, 0) 10.42%, #F7FAFE 100%);
            .front-footer-wrap {
                position: relative;
                min-height: size(458);
                width: 100%;
                max-width: $wrap;
                margin: size(35) auto size(70) auto;
                padding: 0 size(20);
                @include flex(center,wrap,column,center,center);
                @media (min-width: $breakpoint3) {
                    margin: size(35) auto size(70) auto;
                    @include flex(center,wrap,row,center,center); }
                .ff-cta {
                    width: 100%;
                    @media (min-width: $breakpoint3) {
                        width: 50%; } }
                .front-footer-cta {
                    @include flex(center,nowrap,column,center,center);
                    border-radius: size(14);
                    padding: size(23);
                    background-color: $color3;
                    @media (min-width: $breakpoint3) {
                        position: absolute;
                        left: 12%;
                        z-index: 0;
                        padding: size(23) size(43);
                        min-width: size(360);
                        max-width: size(400); }
                    .front-footer-cta__texto {
                        font-family: $fuente;
                        font-size: size(25);
                        line-height: size(29);
                        font-weight: 400;
                        color: $color4; }
                    .front-footer-cta__titulo {
                        font-family: $fuente;
                        font-size: size(25);
                        line-height: size(29);
                        font-weight: 800;
                        color: $color4; }
                    .front-footer-cta__boton {
                        display: inline-block;
                        margin-top: size(13);
                        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
                        border-radius: 3px;
                        padding: size(10) size(23);
                        font-family: $fuente;
                        font-size: size(18);
                        line-height: size(22);
                        font-weight: 700;
                        text-decoration: none;
                        background-color: $white;
                        color: #3C3C3C;
                        transition: background-color .3s linear;
                        &:hover {
                            color: $white;
                            background-color: $color4; } } }
                .front-footer-imagen {
                    @include flex(center,nowrap,column,center,center);
                    @media (min-width: $breakpoint3) {
                        position: absolute;
                        z-index: 100;
                        right: 12%; }
                    img {
                        display: block;
                        @media (min-width: $breakpoint3) {
                            max-width: size(684); } } } } }
        footer {
            background-color: $black;
            .topfooter {
                margin-left: size(20);
                margin-right: size(20);
                padding: size(54) 0;
                border-bottom: 1px solid $white;
                @media (min-width: $breakpoint1) {
                    padding: size(60) 0 size(90) 0;
                    border-bottom: initial; }
                .wrap {
                    @include flex(center,nowrap,column,center,center);
                    width: 100%;
                    @media (min-width: $breakpoint1) {
                        max-width: $wrap;
                        margin: 0 auto;
                        @include flex(space-between,wrap,row,stretch,stretch); }
                    .caja-topfooter {
                        width: 100%;
                        @media (min-width: $breakpoint1) {
                            width: 33%;
                            padding: 0 size(20); } }
                    .caja-topfooter-1 {
                        text-align: center;
                        @media (min-width: $breakpoint1) {
                            text-align: left; }
                        .logo-footer {
                            a:link {
                                display: inline-block; }
                            .logo-img {
                                display: block;
                                width: 100%;
                                min-width: size(264);
                                max-width: size(264);
                                object-fit: contain;
                                object-position: center;
                                @media (min-width: $breakpoint1) {
                                    min-width: size(264);
                                    max-width: size(264); }
                                &:hover {
                                    transform: scale(0.95);
                                    transition: all .2s linear; } } } }
                    .caja-topfooter-2 {
                        .rrss-footer {
                            margin-top: size(38);
                            @media (min-width: $breakpoint1) {
                                margin-top: initial; }
                            .rrss {
                                .rrss-contenedor {
                                    @include flex(center,wrap,row,center,center);
                                    .item-rrss {
                                        .icon-link {
                                            display: inline-block;
                                            text-decoration: none;
                                            border-radius: 50%;
                                            border: 1px solid $white;
                                            padding: size(17);
                                            margin: size(10);
                                            transition: color .3s linear;
                                            @media (min-width: $breakpoint1) {
                                                padding: size(20); }
                                            &:hover {
                                                .icono {
                                                    &::before {
                                                        color: $color3; } } }
                                            .icono {
                                                color: $white;
                                                width: size(49);
                                                height: size(49);
                                                font-size: size(17);
                                                @media (min-width: $breakpoint1) {
                                                    font-size: size(18); } } } } } } } }
                    .caja-topfooter-3 {
                        text-align: center;
                        margin-top: size(37);
                        @media (min-width: $breakpoint1) {
                            margin-top: initial;
                            text-align: right; }
                        .boton_link {
                            display: inline-block;
                            min-width: size(153);
                            white-space: nowrap;
                            text-decoration: none;
                            font-family: $fuente;
                            font-weight: 700;
                            font-size: size(18);
                            line-height: size(21);
                            text-align: center;
                            color: #3C3C3C;
                            background-color: $color3;
                            padding: size(16) size(10);
                            border-radius: size(2);
                            transition: background .3s linear;
                            &:hover {
                                background-color: $white;
                                color: #3C3C3C; } } } } }
            .footer {
                display: none;
                @media (min-width: $breakpoint1) {
                    display: initial;
                    width: 100%; }
                .wrap {
                    @include flex(center,wrap,column,center,center);
                    width: 100%;
                    max-width: $wrap;
                    margin: 0 auto;
                    padding-bottom: size(68);
                    padding-left: size(20);
                    padding-right: size(20);
                    border-bottom: 1px solid $white;
                    @media (min-width: $breakpoint1) {
                        padding-bottom: size(56);
                        @include flex(space-around,wrap,row,stretch,flex-start); }
                    .caja-footer {
                        @include flex(center,wrap,column,center,flex-start);
                        width: 25%;
                        a:link,
                        a:visited {
                            display: block;
                            font-family: $fuente;
                            font-size: size(15);
                            line-height: size(25);
                            font-weight: normal;
                            color: $white;
                            text-decoration: none;
                            &:hover {
                                transition: color .3s linear;
                                color: $color3; } }
                        .menu-titulo {
                            display: block;
                            font-family: $fuente;
                            font-size: size(18);
                            line-height: size(25);
                            font-weight: normal;
                            color: $color3; } } } }
            .sellos {
                padding: size(19) 0 size(35) 0;
                @include flex(center,wrap,row,center,center);
                @media (min-width: $breakpoint1) {
                    padding: size(17) 0 size(34) 0; }
                .sello {
                    margin: 0 size(10);
                    .sello-img {
                        display: block;
                        object-fit: cover;
                        object-fit: contain;
                        min-height: size(56);
                        max-height: size(56); } } }
            .firmas {
                width: 100%;
                padding-bottom: size(19);
                @media (min-width: $breakpoint1) {
                    padding-bottom: size(22); }
                .wrap {
                    position: relative;
                    width: 100%;
                    margin: 0 auto;
                    @media (min-width: $breakpoint1) {
                        @include flex(space-between,nowrap,row,center,center);
                        margin: 0 auto;
                        max-width: $wrap; }
                    .caja-sub-footer-1 {
                        @include flex(center,nowrap,column,center,center);
                        flex-basis: 100%;
                        text-align: center;
                        @media (min-width: $breakpoint1) {
                            flex-basis: 60%;
                            @include flex(space-between,nowrap,row,stretch,stretch);
                            text-align: initial; } }
                    .caja-sub-footer-2 {
                        flex-basis: 100%;
                        @media (min-width: $breakpoint1) {
                            flex-basis: 40%; } }
                    .caja-sub-footer {
                        padding: size(10) size(20) size(5) size(20);
                        font-family: $fuente;
                        font-weight: 300;
                        font-size: size(12);
                        line-height: size(24);
                        font-weight: normal;
                        color: $color1;
                        .menu-menu-lopd-container {
                            .menu {
                                padding: 0px;
                                @include flex(center,nowrap,row,center,center);
                                li {
                                    padding: 0;
                                    text-align: center; }
                                a:link,
                                a:visited {
                                    display: inline-block;
                                    padding: 0 size(10);
                                    color: $white;
                                    text-decoration: none;
                                    font-size: size(12);
                                    color: $white;
                                    text-align: center;
                                    @media (min-width: $breakpoint1) {
                                        text-align: initial; }
                                    &:hover {
                                        transition: color .3s linear;
                                        color: $color3; } } } }
                        .firmas_agencias {
                            position: relative;
                            @include flex(space-between,nowrap,row,center,center);
                            @media (min-width: $breakpoint1) {
                                margin-top: initial;
                                margin-left: size(10); }
                            .dobuss,
                            .bebrand {
                                @include flex(center,nowrap,row,center,center);
                                padding-left: 0.4em;
                                @media (min-width: $breakpoint1) {
                                    @include flex(flex-end,nowrap,row,center,center);
                                    margin-top: -5px; }
                                .link-dobuss,
                                .link-bebrand {
                                    display: block;
                                    padding-top: 5px;
                                    @include flex(center,nowrap,row,center,center);
                                    color: $white;
                                    text-decoration: none;
                                    font-size: size(12);
                                    &:hover {
                                        transition: color .3s linear;
                                        color: $color3; } }
                                img {
                                    margin-left: size(3);
                                    margin-top: size(3);
                                    max-width: size(50); } } } } } } } } }
